import React, { useState, useContext, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'
import FilledInput from '@material-ui/core/FilledInput'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import { NotificationManager } from 'react-notifications'
import { Auth } from 'aws-amplify'
import { onError } from 'config/lib/errorLib'
import PhoneInput from 'components/general/PhoneInput'
import Loader from 'components/general/Loader'
import GuestAPI from 'config/api/GuestAPI'
import { getSiteLogo, getCompanyName, getSiteClass, getCompanyCompliance } from 'config/helpers/Helpers'

// import nphLogo from 'resources/images/nph-logo.png'
// import pcLogo from 'resources/images/pc-logo.png'
import alLogo from 'resources/images/al-logo.jpg'

// core components
import AuthHeader from 'components/argon/Headers/AuthHeader.js'
import componentStyles from 'assets/theme/views/auth/login.js'
import componentStylesButtons from 'assets/theme/components/button.js'
import { generatePassword } from 'config/helpers/Helpers'
import { Typography } from '@material-ui/core'

import DetailsContext from 'config/context/DetailsContext'

import zurich from 'resources/images/zurich-logo.png'
import aviva from 'resources/images/aviva-logo.png'
import irishLife from 'resources/images/irish-life-logo.png'
import standardLife from 'resources/images/standard-life-logo.png'
import newIreland from 'resources/images/new-ireland-logo.png'
import StartPensionForm from 'components/general/Forms/StartPensionForm'
import PensionTransferForm from 'components/general/Forms/PensionTransferForm'
import RetirementPlanningForm from 'components/general/Forms/RetirementPlanningForm'

const useStyles = makeStyles(componentStyles)
const useStylesButtons = makeStyles(componentStylesButtons)

function Login() {
    const classes = { ...useStyles(), ...useStylesButtons() }
    const api = new GuestAPI()
    const theme = useTheme()
    const history = useHistory()
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId
    const viewArea = useRef(null)

    const [phone, setPhone] = useState('')
    const [personalDetails, setPersonalDetails] = useState(null)
    const [pensionDetails, setPensionDetails] = useState(null)
    const [type, setType] = useState(null)
    const [user, setUser] = useState(null)
    const [stage, setStage] = useState('select')
    const [confirmationCode, setConfirmationCode] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        checkUrlParams()
    }, [])

    async function checkUrlParams() {
        const urlStage = new URLSearchParams(window.location.search).get('stage')
        console.log('stage: ', urlStage)

        if (urlStage) {
            setStage(urlStage)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

		if (stage === 'login') {
			signIn()
		}
        if (stage === 'successfulRegister') {
            signIn()
        }
        else if (stage === 'signInChallenge') {
            sendConfirmationCode()
        }
    }

    // function updateDetails(type, personalDetails, pensionDetails) {
    //     setType(type)
    //     setPersonalDetails(personalDetails)
    //     setPensionDetails(pensionDetails)
    // }

    async function register(type, personalDetails, pensionDetails) {
        setLoading(true)
        let password = generatePassword()
        console.log(password)
        let cognitoData = {
            'username': personalDetails.phone,
            'password': password,
            'attributes': {
                'name': personalDetails.firstName + ' ' + personalDetails.lastName,
                'email': personalDetails.email,
                'phone_number': personalDetails.phone,
                'custom:account_type': 'customer'
            }
        }
        console.log(cognitoData)

        try {
            const user = await Auth.signUp(cognitoData)
            console.log('user: ', user)
            const response = await api.addApplication(companyId, type, personalDetails.phone, personalDetails, pensionDetails)
            console.log('response: ', response)
            setUser(user)
            setPhone(personalDetails.phone)
            signIn(personalDetails.phone)
        } catch(e) {
            console.log(e)
            console.log(e.code)
            if (e.code === 'UsernameExistsException') {
                NotificationManager.error('An account has already been registered with the phone number provided. Please log in.')
                setStage('login')
            }
            else if (e.code === 'InvalidParameterException') {
                NotificationManager.error('Please ensure you have entered a valid phone number (+353861234567)')
            }
            else {
                onError(e)
            }
            setLoading(false)
        }
    }

    async function signIn(regPhone = null) {
        setLoading(true)
        // history.push({
        //     search: ''
        // })
        let signinPhone = regPhone || phone
        console.log('signinPhone: ', signinPhone)
        try {
            const user = await Auth.signIn(signinPhone)
            console.log('user: ', user)
            setUser(user)
            setStage('signInChallenge')
            setLoading(false)
            viewArea.current.scrollIntoView()
        } catch(e) {
            console.log(e)
            console.log(e.code)
            if (e.code === 'UserNotConfirmedException') {
                //NotificationManager.error('You must confirm your account in order to log in. Please check your email for a confirmation link')
                //TODO; change this to stage on login screen (for brokers)
                setStage('confirmation')
            }
            else if (e.code === 'UserNotFoundException') {
                NotificationManager.error('An account with this email has not yet been registered.')
            }
            else if (e.code === 'NotAuthorizedException') {
                NotificationManager.error('The email or password you have entered is incorrect')
            }
            else {
                onError(e)
            }
            setLoading(false)
        }
    }

    function sendConfirmationCode() {
        if (confirmationCode.length > 0) {
            signInChallenge()
        }
        else {
            NotificationManager.error('Please enter the code that was sent to ' + phone)
        }
    }

    async function signInChallenge() {
        setLoading(true)
        try {
            // const info = await Auth.currentAuthenticatedUser()
            // console.log('info: ', info)
            console.log('code: ', confirmationCode)
            const response = await Auth.sendCustomChallengeAnswer(user, confirmationCode)
            console.log('response: ', response)
            //console.log('response answer correct: ', response.response.answerCorrect)
            //window.location.reload() // test this
            if (companyId === 'al') {
                try {
                    window.dataLayer.push({'event': 'details-confirmed'})
                } catch(e) {
                    console.log(e)
                }
            }
            window.location.replace('/application' + history.location.search)
        } catch(e) {
            // Handle 3 error thrown for 3 incorrect attempts. 
            console.log(e)
            console.log(e.code)
            onError(e)
            setLoading(false)
        }
    }

    function getPhoneTitle() {
        if (type === 'Start Pension') {
            return 'Start a new pension'
        }
        else if (type === 'Pension Transfer') {
            return 'Transfer an existing pension'
        }
        else if (type === 'Retirement Planning') {
            return 'Plan for retirement'
        }
    }
   
    return (
        <>  
            <Grid container direction='row' justifyContent='center' alignItems='center' style={{width: '100%', padding: '1rem 3rem'}}>
                <Grid item xs={12} md={6} className='login-logo-container'>
                    <img className={'login-logo ' + getSiteClass(companyId)} src={getSiteLogo(companyId)} alt='logo' onClick={() => setStage('select')} />
                </Grid>
                <Grid item xs={12} md={6}>
                    
                    {/*companyId === 'nph' &&
                        <p className={'powered-by-top ' + getSiteClass(companyId)}><span>Powered by</span> <img style={{width: '200px'}} src={alLogo} alt='logo' /></p>
                    */} 
                    {companyId === 'al' &&
                        <p className={'powered-by-top ' + getSiteClass(companyId)}><span>Powered by</span> <img style={{width: '200px'}} src={alLogo} alt='logo' /></p>
                    } 
                    {companyId === 'bq' &&
                        <p className={'powered-by-top ' + getSiteClass(companyId)}><span>Powered by</span> BeQuick Media</p>
                    } 
                    
                </Grid>
            </Grid>

            <Box ref={viewArea}></Box>

            {stage === 'select' &&
                <AuthHeader
                    title='Start a Pension'
                    description={'Start your pension (PRSA) in under 5 minutes with Aspect Life & Pensions. Compare every pension plan in Ireland.'}
                    site={getSiteClass(companyId)}
                />
            }
            {stage === 'startPension' &&
                <AuthHeader
                    title='Start a new pension'
                    description={'Register your details with ' + getCompanyName() + ' to get started'}
                    site={getSiteClass(companyId)}
                />
            }
            {stage === 'pensionTransfer' &&
                <AuthHeader
                    title='Transfer an existing pension'
                    description={'Register your details with ' + getCompanyName() + ' to get started'}
                    site={getSiteClass(companyId)}
                />
            }
            {stage === 'retirementPlanning' &&
                <AuthHeader
                    title='Plan for retirement'
                    description={'Register your details with ' + getCompanyName() + ' to get started'}
                    site={getSiteClass(companyId)}
                />
            }
            {/*stage === 'phoneInput' &&
                <AuthHeader
                    title={getPhoneTitle()}
                    description={'Register your details with ' + getCompanyName() + ' to get started'}
                    site={getSiteClass(companyId)}
                />
            */}
            {stage === 'login' &&
                <AuthHeader
                    title='Continue your pension application'
                    //description='Sign in to continue your pension application'
                />
            }
            {stage === 'signInChallenge' &&
                <AuthHeader
                    title='Check your phone'
                    description={'We just sent a confirmation code via SMS to ' + phone}
                    site={getSiteClass(companyId)}
                />
            }
            {stage === 'successfulRegister' &&
                <AuthHeader
                    title='Congratulations!'
                    description='Successful registration'
                    site={getSiteClass(companyId)}
                />
            }
          
            <Container
                component={Box}
                maxWidth='xl'
                marginTop='-12rem'
                paddingBottom='3rem'
                position='relative'
                zIndex='101'
            >
                {stage === 'select' &&
                    <>
                    {/*
                        <Box component={Grid} container justifyContent='center'>
                            <Grid item xs={12} lg={5} md={7}>
                                <Card classes={{ root: classes.cardRoot }} style={{backgroundColor: '#fff'}}>
                                    <CardContent>
                                        <Box textAlign={'center'} style={{margin: '1rem'}}>
                                            <Typography variant='h2'>Customer Notice</Typography>
                                        </Box>
                                        <Box textAlign={'center'} style={{margin: '1rem'}}>
                                            <Box
                                                color={theme.palette.gray[600]}
                                                textAlign='center'
                                                marginBottom='1rem'
                                                marginTop='.5rem'
                                                fontSize='1rem'
                                            >
                                                <p>This service has moved. If you would like to start a new pension, or are trying to continue your application online, please click the button below.</p>
                                            </Box>
                                        </Box>
                                        <Box textAlign={'center'} style={{margin: '1rem'}}>
                                            <Button
                                                component={Box}
                                                color='primary'
                                                variant='contained'
                                                size='medium'
                                                //disabled={checkRequiredFields()}
                                                onClick={() => window.location.replace('https://apply.qfinancial.ie')}
                                            >
                                                Go to start a pension platform
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Box>
                        */}
                        <Box component={Grid} container justifyContent='center'>
                            <Grid item xs={12} lg={5} md={7}>
                                <Card classes={{ root: classes.cardRoot }} style={{backgroundColor: '#fff'}}>
                                    <CardContent>
                                        <Box textAlign={'center'} style={{margin: '1rem'}}>
                                            <Typography variant='h2'>Start a new pension</Typography>
                                        </Box>
                                        <Box textAlign={'center'} style={{margin: '1rem'}}>
                                            <Box
                                                color={theme.palette.gray[600]}
                                                textAlign='center'
                                                marginBottom='1rem'
                                                marginTop='.5rem'
                                                fontSize='1rem'
                                            >
                                                <p>I would like to start a new pension application online</p>
                                            </Box>
                                        </Box>
                                        <Box textAlign={'center'} style={{margin: '1rem'}}>
                                            <Button
                                                component={Box}
                                                color='primary'
                                                variant='contained'
                                                size='medium'
                                                //disabled={checkRequiredFields()}
                                                onClick={() => setStage('startPension')}
                                            >
                                                Start New Application
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={5} md={7}>
                                <Card classes={{ root: classes.cardRoot }} style={{backgroundColor: '#fff'}}>
                                    <CardContent>
                                        <Box textAlign={'center'} style={{margin: '1rem'}}>
                                            <Typography variant='h2'>Retrieve existing application</Typography>
                                        </Box>
                                        <Box textAlign={'center'} style={{margin: '1rem'}}>
                                            <Box
                                                color={theme.palette.gray[600]}
                                                textAlign='center'
                                                marginBottom='1rem'
                                                marginTop='.5rem'
                                                fontSize='1rem'
                                            >
                                                <p>I would like to continue an existing pension application</p>
                                            </Box>
                                        </Box>
                                        <Box textAlign={'center'} style={{margin: '1rem'}}>
                                            <Button
                                                component={Box}
                                                color='primary'
                                                variant='contained'
                                                size='medium'
                                                //disabled={checkRequiredFields()}
                                                onClick={() => setStage('login')}
                                            >
                                                Continue Application
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={5} md={7}>
                                <p className={getSiteClass(companyId) + ' hover-text'} style={{textAlign: 'center'}} onClick={() => setStage('pensionTransfer')}>I would like to transfer an existing pension</p>
                                <p className={getSiteClass(companyId) + ' hover-text'} style={{textAlign: 'center'}} onClick={() => setStage('retirementPlanning')}>I would like to discuss retirement planning</p>
                            </Grid>
                        </Box>
                    </>
                }
                {stage === 'startPension' &&
                    <StartPensionForm 
                        companyId={companyId}
                        loading={loading}
                        //updateStage={(stage) => setStage(stage)}
                        register={(type, personalDetails, pensionDetails) => register(type, personalDetails, pensionDetails)}
                    />
                }
                {stage === 'pensionTransfer' &&
                    <PensionTransferForm 
                        companyId={companyId}
                        loading={loading}
                        //updateStage={(stage) => setStage(stage)}
                        register={(type, personalDetails, pensionDetails) => register(type, personalDetails, pensionDetails)}
                    />
                }
                {stage === 'retirementPlanning' &&
                    <RetirementPlanningForm 
                        companyId={companyId}
                        loading={loading}
                        //updateStage={(stage) => setStage(stage)}
                        register={(type, personalDetails, pensionDetails) => register(type, personalDetails, pensionDetails)}
                    />
                }
                {stage === 'phoneInput' &&
                    <form onSubmit={handleSubmit}>
                        <Box component={Grid} container justifyContent='center'>
                            <Grid item xs={12} lg={6} md={7}>
                                <Card classes={{ root: classes.cardRoot }} style={{backgroundColor: '#fff'}}>
                                    {/*<Button size='small' variant='contained' color='secondary' style={{width: '60px', margin: '0.5rem'}} onClick={() => setStage('select')}>Back</Button>*/}
                                    <CardContent classes={{ root: classes.cardContent }}>
                                        <Box
                                            color={theme.palette.gray[600]}
                                            textAlign='center'
                                            marginBottom='2rem'
                                            marginTop='.5rem'
                                            fontSize='1rem'
                                        >
                                            <p>Your mobile number is your login to view, edit and submit your pension application online. We will send you a verification code to register your account.</p>
                                        </Box>
                                        <Grid container style={{marginBottom: '1.5rem'}}>
                                            <Grid item xs={12}>
                                                <FormGroup>
                                                    <FormLabel>Phone Number</FormLabel>
                                                    <FormControl
                                                        variant='filled'
                                                        component={Box}
                                                        width='100%'
                                                    >
                                                        <PhoneInput
                                                            phone={phone}
                                                            updatePhone={(phone) => setPhone(phone)}
                                                        />
                                                    </FormControl>
                                                </FormGroup>
                                            </Grid>
                                        </Grid>

                                        <Box
                                            textAlign='center'
                                            marginTop='1.5rem'
                                            marginBottom='1.5rem'
                                        >
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                //onClick={() => signIn()}
                                                type='submit'
                                                disabled={loading}
                                            >
                                                {!loading ? (
                                                    <>Get Code</>
                                                ) : (
                                                    <Loader height={12} />
                                                )}
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Box>
                    </form>            
                }
                {stage === 'login' &&
                    <form onSubmit={handleSubmit}>
                        <Box component={Grid} container justifyContent='center'>
                            <Grid item xs={12} lg={5} md={7}>
                                <Card classes={{ root: classes.cardRoot }} style={{backgroundColor: '#fff'}}>
                                    {/*<Button size='small' variant='contained' color='secondary' style={{width: '60px', margin: '0.5rem'}} onClick={() => setStage('select')}>Back</Button>*/}
                                    <CardContent classes={{ root: classes.cardContent }}>
                                        <Box
                                            color={theme.palette.gray[600]}
                                            textAlign='center'
                                            marginBottom='1rem'
                                            marginTop='.5rem'
                                            fontSize='1rem'
                                        >
                                            <p>Sign in with your phone number</p>
                                        </Box>

                                        <PhoneInput
                                            phone={phone}
                                            updatePhone={(phone) => setPhone(phone)}
                                        />
                                        
                                        <Box
                                            textAlign='center'
                                            marginTop='1.5rem'
                                            marginBottom='1.5rem'
                                        >
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                //onClick={() => signIn()}
                                                type='submit'
                                                disabled={loading}
                                            >
                                                {!loading ? (
                                                    <>Sign in</>
                                                ) : (
                                                    <Loader height={12} />
                                                )}
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                                
                                <Grid container component={Box} marginTop='1rem'>
                                    <Grid item xs={12} component={Box} textAlign='center'>
                                        {/*
                                        <a
                                            href='#'
                                            onClick={(e) => openForgotPasswordModal(e)}
                                            className={classes.footerLinks}
                                            style={{color: '#1a73e8', opacity: '50%'}}
                                        >
                                            Forgot password
                                        </a>
                                        */}
                                        <a
                                            href='#'
                                            onClick={(e) => setStage('startPension')}
                                            className={getSiteClass(companyId) + ' ' + classes.footerLinks}
                                            style={{opacity: '70%'}}
                                        >
                                            Dont have a pension application? Start one now
                                        </a>
                                    </Grid>
                                    
                                    <Grid item xs={6} component={Box} textAlign='right'>
                                        {/*
                                        <a
                                            href='/register'
                                            //onClick={(e) => e.preventDefault()}
                                            className={classes.footerLinks}
                                            style={{color: '#45129e', opacity: '50%'}}
                                        >
                                            Create new account
                                        </a>
                                        */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                }
                {stage === 'signInChallenge' &&
                    <form onSubmit={handleSubmit}>
                        <Box component={Grid} container justifyContent='center'>
                            <Grid item xs={12} lg={5} md={7}>
                                <Card classes={{ root: classes.cardRoot }} style={{backgroundColor: '#fff'}}>
                                    <CardContent classes={{ root: classes.cardContent }}>
                                        <Box
                                            color={theme.palette.gray[600]}
                                            textAlign='center'
                                            marginBottom='1rem'
                                            marginTop='.5rem'
                                            fontSize='1rem'
                                        >
                                            <p>Enter your confirmation code below</p>
                                        </Box>

                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                            marginBottom='1rem!important'
                                        >
                                            <FilledInput
                                                autoComplete='off'
                                                type='text'
                                                placeholder='Confirmation Code'
                                                value={confirmationCode}
                                                onChange={(e) => setConfirmationCode(e.target.value)}
                                            />
                                        </FormControl>
                                        
                                        <Box
                                            textAlign='center'
                                            marginTop='1.5rem'
                                            marginBottom='1.5rem'
                                        >
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                //onClick={() => confirmAccount()}
                                                type='submit'
                                                disabled={loading}
                                            >
                                                {!loading ? (
                                                    <>Confirm</>
                                                ) : (
                                                    <Loader height={12} />
                                                )}
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                                <Grid container component={Box} marginTop='1rem'>
                                    <Grid item xs={12} component={Box} textAlign='center'>
                                        <a
                                            href='#'
                                            onClick={(e) => signIn()}
                                            className={getSiteClass(companyId) + ' ' + classes.footerLinks}
                                            style={{color: '#015f63', opacity: '50%'}}
                                        >
                                            Resend Code
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                }
                {stage === 'successfulRegister' &&
                    <form onSubmit={handleSubmit}>
                        <Box component={Grid} container justifyContent='center'>
                            <Grid item xs={12} lg={5} md={7}>
                                <Card classes={{ root: classes.cardRoot }} style={{backgroundColor: '#fff'}}>
                                    <CardContent classes={{ root: classes.cardContent }}>
                                        <Box
                                            color={theme.palette.gray[600]}
                                            textAlign='center'
                                            marginBottom='1rem'
                                            marginTop='.5rem'
                                            fontSize='1rem'
                                        >
                                            <p>Your account is now active and you are ready to start your application.</p>
                                            <p>You can make changes to your application at any time by logging in with your phone number.</p>
                                        </Box>
                                        
                                        <Box
                                            textAlign='center'
                                            marginTop='1.5rem'
                                            marginBottom='1.5rem'
                                        >
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                //onClick={() => confirmAccount()}
                                                type='submit'
                                                disabled={loading}
                                            >
                                                {!loading ? (
                                                    <>Start Application</>
                                                ) : (
                                                    <Loader height={12} />
                                                )}
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Box>
                    </form>
                }
                <Grid container justifyContent='center' alignItems='center' style={{marginTop: '2rem'}}>
                    <Grid item xs={12}>
                        <Typography variant='h2' style={{textAlign: 'center'}}>Pension Providers</Typography>
                    </Grid>
                    <Grid item xs={12} style={{marginTop: '2rem', marginBottom: '2rem'}}>
                        <Grid container className='home-logo-container'>
                            <Grid item xs={4} md={2} className='logo-item'><img className='provider-logo home' src={zurich} alt='zurich' /></Grid>
                            <Grid item xs={4} md={2} className='logo-item'><img className='provider-logo home' src={irishLife} alt='irish life' /></Grid>
                            <Grid item xs={4} md={2} className='logo-item'><img className='provider-logo home' src={aviva} alt='aviva' /></Grid>
                            <Grid item xs={4} md={2} className='logo-item'><img className='provider-logo home' src={standardLife} alt='standard life' /></Grid>
                            <Grid item xs={4} md={2} className='logo-item'><img className='provider-logo home' src={newIreland} alt='new ireland' /></Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Box
                    fontSize='12px'
                    color={theme.palette.gray[600]}
                    marginBottom='0rem'
                    marginTop='2rem'
                    textAlign='center'
                    //className='online-discount'
                >
                    <Box component='small' fontSize='100%'>
                        {getCompanyCompliance(companyId)}
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default Login