import React, { useEffect, useRef } from 'react'
import { useLocation, Switch, Route, Redirect } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import componentStyles from 'assets/theme/layouts/auth.js'

import Login from 'scenes/guest/Login'
import TermsConditions from 'scenes/guest/TermsConditions'
//import Verify from 'scenes/guest/Verify'
//import PricingRules from 'scenes/guest/PricingRules'
//import CompletedSignature from 'scenes/guest/CompletedSignature'

const useStyles = makeStyles(componentStyles)

const Main = () => {
    const classes = useStyles()
    const mainContent = useRef(null)
    const location = useLocation()

    // useEffect(() => {
    //     document.body.classList.add(classes.bgDefault)
    //     return () => {
    //         document.body.classList.remove(classes.bgDefault)
    //     }
    // })

    // useEffect(() => {
    //     document.documentElement.scrollTop = 0
    //     document.scrollingElement.scrollTop = 0
    //     mainContent.current.scrollTop = 0
    // }, [location])

    return (
        <>
            <div className='main-content guest' ref={mainContent}>
                <Switch>
                    <Route exact path='/terms-and-conditions' component={TermsConditions} />
                    <Route exact path='/' component={Login} />
                    <Redirect from='*' to='/' />
                </Switch>
            </div>
        </>
    )
}

export default Main