import React, { useState, useContext, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'
import FilledInput from '@material-ui/core/FilledInput'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import { NotificationManager } from 'react-notifications'
import { Auth } from 'aws-amplify'
import { onError } from 'config/lib/errorLib'
import PhoneInput from 'components/general/PhoneInput'
import Loader from 'components/general/Loader'
import GuestAPI from 'config/api/GuestAPI'
import { getSiteLogo, getSiteClass, getCompanyCompliance } from 'config/helpers/Helpers'

import nphLogo from 'resources/images/nph-logo.png'
import pcLogo from 'resources/images/pc-logo.png'
import alLogo from 'resources/images/al-logo.jpg'

// core components
import AuthHeader from 'components/argon/Headers/AuthHeader.js'
import componentStyles from 'assets/theme/views/auth/login.js'
import componentStylesButtons from 'assets/theme/components/button.js'
import { generatePassword } from 'config/helpers/Helpers'
import { Typography } from '@material-ui/core'

import DetailsContext from 'config/context/DetailsContext'



function TermsConditions() {
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId
    const history = useHistory()
    return (
        <>
            <Grid container direction='row' justifyContent='center' alignItems='center' style={{width: '100%', padding: '1rem 3rem'}}>
                <Grid item xs={12} md={6} className='login-logo-container'>
                    <img className={'login-logo ' + getSiteClass(companyId)} src={getSiteLogo(companyId)} alt='logo' onClick={() => history.push('/login')} />
                </Grid>
                <Grid item xs={12} md={6}>
                    
                    {/*companyId === 'nph' &&
                        <p className={'powered-by-top ' + getSiteClass(companyId)}><span>Powered by</span> <img style={{width: '200px'}} src={alLogo} alt='logo' /></p>
                    */} 
                    {companyId === 'al' &&
                        <p className={'powered-by-top ' + getSiteClass(companyId)}><span>Powered by</span> <img style={{width: '200px'}} src={alLogo} alt='logo' /></p>
                    } 
                    {companyId === 'bq' &&
                        <p className={'powered-by-top ' + getSiteClass(companyId)}><span>Powered by</span> BeQuick Media</p>
                    } 
                    
                </Grid>
            </Grid>
            <div className='terms-conditions'>  
                <h1 style={{ textAlign: 'center' }}>Terms & Conditions</h1>
                <h2>1. Introduction</h2>
                <p>
                These Terms & Conditions govern your use of the Start a Pension system, operated by the National Pension Helpline ("the company"). By using this platform, you agree to be bound by these Terms & Conditions. If you do not agree, you must not use this system.
                </p>
                <p>
                The platform enables individuals to submit their personal data for the purpose of facilitating connections with Aspect Life & Pensions. This system is subject to the company’s Privacy Policy, which outlines how personal data is collected, used, and stored.
                </p>

                <h2>2. Services Provided</h2>
                <p>
                The Start a Pension system is an online platform designed to collect relevant personal and financial information necessary to assist in pension-related inquiries. Upon submission, your personal data will be securely transferred exclusively to Aspect Life & Pensions, a central-bank regulated partner, to provide further guidance and services.
                </p>

                <h2>3. Use of Personal Data</h2>
                <h3>a) Data Collection:</h3>
                <p>
                By using the system, you consent to the collection of the following personal information:
                </p>
                <ul>
                <li>Name</li>
                <li>Address</li>
                <li>Contact details (email and phone number)</li>
                <li>Employment and financial details relevant to your pension inquiry</li>
                </ul>

                <h3>b) Data Sharing:</h3>
                <p>
                Your data will be shared solely with Aspect Life & Pensions for the purposes of:
                </p>
                <ul>
                <li>Pension consultation</li>
                <li>Service delivery related to your pension needs</li>
                </ul>

                <h3>c) Data Retention:</h3>
                <p>
                By using the Start a Pension system, you are creating a profile that allows you to log in, view, and edit your personal information at any time. This profile is stored for your convenience to facilitate ongoing access and updates. You may also choose to delete your account entirely, at which point your data will be permanently removed from our system, unless otherwise required for regulatory purposes.
                </p>
                <p>
                Please note that this document supersedes the more general Privacy Policy document wherever discrepancies arise in relation to the Start a Pension system. For more details on data processing, refer to the Privacy Policy.
                </p>

                <h2>4. User Obligations</h2>
                <p>
                When using the Start a Pension system, you agree to:
                </p>
                <ul>
                <li>Provide accurate and complete information.</li>
                <li>Use the platform for lawful purposes only.</li>
                <li>Notify the company immediately of any unauthorized access or use of your account.</li>
                </ul>
                <p>
                Failure to comply may result in restricted access to the platform or termination of services.
                </p>

                <h2>5. Disclaimer of Liability</h2>
                <p>
                The company is not responsible for:
                </p>
                <ul>
                <li>Decisions or actions taken by Aspect Life & Pensions based on the information provided.</li>
                <li>Any loss or damage arising from inaccuracies or omissions in the data you submit.</li>
                </ul>
                <p>
                The company facilitates the connection but does not provide pension advice or assume responsibility for third-party services.
                </p>

                <h2>6. Changes to the Terms & Conditions</h2>
                <p>
                The company reserves the right to modify these Terms & Conditions at any time. Updates will be communicated through the platform. Continued use of the system after changes are implemented constitutes acceptance of the updated terms.
                </p>

                <h2>7. Governing Law</h2>
                <p>
                These Terms & Conditions are governed by the laws of Ireland. Any disputes arising from the use of the system shall be subject to the exclusive jurisdiction of the Irish courts.
                </p>

                <h2>8. Contact Information</h2>
                <p>
                If you have questions about these Terms & Conditions or your use of the Start a Pension system, please contact us:
                </p>
                
                <p>National Pension Helpline</p>
                <p>151 Thomas Street, Dublin 8, D08 PY5E</p>
                <p>Phone: 0818 123 223</p>
                <p>Email: <a href="mailto:info@nationalpensionhelpline.ie">info@nationalpensionhelpline.ie</a></p>
                <p>Website: <a href="https://nationalpensionhelpline.ie" target="_blank" rel="noopener noreferrer">nationalpensionhelpline.ie</a></p>
            </div>
        </>
    )
}

export default TermsConditions