import React, { useState, useEffect } from 'react'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import ApplicationsTable from 'components/broker/ApplicationsTable'
import { getPipelineCount } from 'config/helpers/Helpers'

function Applications() {
    const api = new BrokerAPI()

    const [loading, setLoading] = useState(true)
    //const [allApplications, setAllApplications] = useState([])
    const [details, setDetails] = useState({})
    const [brokers, setBrokers] = useState([])
    const [applications, setApplications] = useState([])
    const [showApplications, setShowApplications] = useState([])
    const [stage, setStage] = useState('new')
    const [pipelineCount, setPipelineCount] = useState({})
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getApplications()
    }, [])

    async function getApplications() {
        try {
            const details = await api.getDetails()
            const brokers = await api.getBrokers()
            
            let response = await api.getApplications()
            console.log('applications: ', response)

            response.sort((a, b) => {
                return b.createdAt - a.createdAt
            })
            const updatedResponse = response.map(item => ({
                ...item,
                name: item.personalDetails.firstName + ' ' + item.personalDetails.lastName, // Copy the nested value to the top level
                email: item.personalDetails.email
            })).sort((a, b) => b.createdAt - a.createdAt)
            console.log('updatedResponse: ', updatedResponse)

            //setAllApplications(response)
            setDetails(details)
            setBrokers(brokers)
            updatePipelineCount(updatedResponse)
            setApplications(updatedResponse)
            setShowApplications(updatedResponse.filter((application) => application.stage === 'new'))
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function refreshApplications() {
        try {
            let response = await api.getApplications()
            console.log('applications: ', response)

            response.sort((a, b) => {
                return b.createdAt - a.createdAt
            })

            //setAllApplications(response)
            updatePipelineCount(response)
            setApplications(response)
            setShowApplications(response.filter((application) => application.stage === stage))
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updatePipelineCount(applications) {
        let count = getPipelineCount(applications)
        setPipelineCount(count)
    }

    function updateStage(stage, applications) {
        console.log('stage: ', stage)
        console.log('update stage applications: ', applications)

        setShowApplications(applications.filter((application) => application.stage === stage))
        setStage(stage)
    }

    function updateApplication(application) {
        const index = applications.findIndex(oldApplication => (oldApplication.phone === application.phone))
        console.log('application index: ', index)

        let newApplications = [...applications]
        newApplications[index] = application
        console.log(newApplications)
        
        setApplications(newApplications)
        updatePipelineCount(newApplications)
        updateStage(stage, newApplications)
    }

    function removeApplication(application) {
        const index = applications.findIndex(oldApplication => (oldApplication.phone === application.phone))
        console.log('application index: ', index)

        let newApplications = [...applications]
        newApplications.splice(index, 1)
        console.log('new applications: ', newApplications)
        
        setApplications(newApplications)
        updatePipelineCount(newApplications)
        updateStage(stage, newApplications)
        showSuccessAlert('Application has been removed')
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                //classes={{ root: classes.containerRoot }}
            >
                {alert}
                {!loading ? (
                    <Grid 
                        container 
                        style={{marginTop: '2rem'}}
                        //alignItems='center'
                        justifyContent='center'
                        
                    >
                        <Grid item xs={12}>
                            <ApplicationsTable 
                                allApplications={applications}
                                applications={showApplications}
                                pipelineCount={pipelineCount}
                                stage={stage}
                                updateStage={(stage) => updateStage(stage, applications)}
                                updateApplication={(application) => updateApplication(application)}
                                removeApplication={(lead, application) => removeApplication(lead, application)}
                                //updateStageMultiple={(stage, selected) => updateStageMultiple(stage, selected)}
                                //updateBrokerMultiple={(broker, selected) => updateBrokerMultiple(broker, selected)}
                                updatePipelineCount={(applications) => updatePipelineCount(applications)}
                                refreshApplications={() => refreshApplications()}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                                brokers={brokers}
                                details={details}
                                //product={product}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default Applications