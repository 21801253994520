import { primaryGoalOptions, primaryGoalOptionsPc } from 'config/constants/Constants'
import nphLogo from 'resources/images/nph-logo.png'
import pcLogo from 'resources/images/pc-logo.png'
//import alLogo from 'resources/images/al-logo.jpg'

export function generatePassword() {
    const alpha = 'abcdefghijklmnopqrstuvwxyz'
    const calpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const num = '1234567890'
    const specials = '$!@'
    const options = [alpha, alpha, alpha, calpha, calpha, num, num, specials]
    let opt, choose
    let pass = ''
    
    for ( let i = 0; i < 8; i++ ) {
        opt = Math.floor(Math.random() * options.length)
        choose = Math.floor(Math.random() * (options[opt].length))
        pass = pass + options[opt][choose]
        options.splice(opt, 1)
    }

    return pass
}

export function getPipelineCount(leads) {
	console.log('count leads...')
	let newLead = 0
	let appSubmitted = 0
	let discoveryCall = 0
	let meetingScheduled = 0
	let meetingComplete = 0
	let pensionSubmitted = 0
	let won = 0
	let lost = 0


	for (var lead of leads) {
		if (lead.stage === 'new') {
			newLead++
		}
		if (lead.stage === 'appSubmitted') {
			appSubmitted++
		}
		if (lead.stage === 'discoveryCall') {
			discoveryCall++
		}
		if (lead.stage === 'meetingScheduled') {
			meetingScheduled++
		}
		if (lead.stage === 'meetingComplete') {
			meetingComplete++
		}
		if (lead.stage === 'pensionSubmitted') {
			pensionSubmitted++
		}
		if (lead.stage === 'won') {
			won++
		}
		if (lead.stage === 'lost') {
			lost++
		}
	}

	let count = {
		new: newLead,
		appSubmitted,
		discoveryCall,
		meetingScheduled,
		meetingComplete,
		pensionSubmitted,
		won,
		lost
	}
	console.log('count: ', count)
	return count
}

export function getLeadActivityTimeline(lead, tasks) {
	let items = [];

	items.push({category: 'core', label: 'Application Created', timestamp: lead.createdAt - 100})

	if (lead.callPreference) {
		items.push({category: 'call-preference', label: 'Call Preference', timestamp: lead.createdAt, note: lead.callPreference})
	}

	for (var note of lead.notes) {
		items.push({category: 'note', label: 'Note', timestamp: note.createdAt, note: note})
	}
	// for (var call of lead.calls) {
	// 	items.push({category: 'call', label: 'Call', timestamp: call.date, call: call})
	// }
	// for (var task of tasks) {
	// 	// may have to call get tasks here or before - maybe just when someone opens the lead modal - or if no tasks get tasks and can use on both screens
	// 	items.push({category: 'task', label: 'Task', timestamp: task.date, task: task})
	// }

	if (lead.activity.length > 0) {
		items = items.concat(lead.activity)
	}

	items.sort((a, b) => {
		return new Date(b.timestamp) - new Date(a.timestamp)
	})

	return items
}

export function getComplete(application) {
	console.log('get complete: ', application)
	let personalDetails = Object.keys(application.personalDetails).length > 0 ? true : false
	let riskDetails = Object.keys(application.riskDetails).length > 0 ? true : false
	let pensionDetails = Object.keys(application.pensionDetails).length > 0 ? true : false
	let documents = Object.keys(application.documents).length > 0 ? true : false
	//let complianceDocuments = Object.keys(application.complianceDocuments).length > 0 ? true : false
	let providerDetails = Object.keys(application.providerDetails).length > 0 ? true : false

	// personal details
	for (const [key, value] of Object.entries(application.personalDetails)) {
		if (!value) {
			personalDetails = false
		}
	}
	// risk details
	for (const [key, value] of Object.entries(application.riskDetails)) {
		if (!value) {
			riskDetails = false
		}
	}
	// pension details
	for (const [key, value] of Object.entries(application.pensionDetails)) {
		if (key !== 'value') {
			if (!value) {
				pensionDetails = false
			}
		}
	}
	// documents
	for (const [key, value] of Object.entries(application.documents)) {
		if (!value) {
			documents = false
		}
	}
	// compliance documents
	// for (const [key, value] of Object.entries(application.complianceDocuments)) {
	// 	console.log(value)
	// 	if (!value) {
	// 		complianceDocuments = false
	// 	}
	// }
	// provider details
	for (const [key, value] of Object.entries(application.providerDetails)) {
		console.log(value)
		if (!value) {
			providerDetails = false
		}
	}

	return {
		personalDetails,
		riskDetails,
		pensionDetails,
		documents,
		//complianceDocuments,
		providerDetails
	}
}

export function getPercentageComplete(application) {
	//console.log('get percentage complete: ', application)
	let fieldsCompleted = 0
	let totalFields = Object.keys(application.personalDetails).length + Object.keys(application.riskDetails).length + Object.keys(application.pensionDetails).length
	//console.log('total fields: ', totalFields)

	// personal details
	for (const [key, value] of Object.entries(application.personalDetails)) {
		if (value && value.toString().length > 0) {
			fieldsCompleted += 1
		}
	}
	// risk details
	for (const [key, value] of Object.entries(application.riskDetails)) {
		if (value && value.toString().length > 0) {
			fieldsCompleted += 1
		}
	}
	// pension details
	for (const [key, value] of Object.entries(application.pensionDetails)) {
		if (key !== 'value') {
			if (value && value.toString().length > 0) {
				fieldsCompleted += 1
			}
		}
	}
	// documents
	// for (const [key, value] of Object.entries(application.documents)) {
	// 	if (value && value.toString().length > 0) {
	// 		fieldsCompleted += 1
	// 	}
	// }
	//console.log('fields completed: ', fieldsCompleted)
	
	return ((fieldsCompleted / totalFields) * 100).toFixed() + '%'
}

export function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age || '';
}

export function getSiteClass(companyId) {
	let cl = ''
	if (companyId === 'nph') {
		cl = 'nph'
	}
	if (companyId === 'al') {
		cl = 'pc'
	}
	if (companyId === 'bq') {
		cl = ''
	}
	return cl
}

export function getSiteLogo(companyId) {
	let logo = null
	if (companyId === 'bq') {
		logo = null
	}
	if (companyId === 'nph') {
		logo = nphLogo
	}
	if (companyId === 'al') {
		logo = pcLogo
	}
	return logo
}

export function getCompanyName(companyId) {
	let name = ''
	if (companyId === 'nph') {
		name = 'Aspect Life & Pensions'
	}
	if (companyId === 'al') {
		name = 'Aspect Life & Pensions'
	}
	if (companyId === 'bq') {
		name = 'BeQuick Media'
	}
	return name
}

export function getCompanyCompliance(companyId) {
	let text = ''
	if (companyId === 'nph') {
		text = 'All rights reserved. Copyright © National Pension Helpline. Powered by Aspect Life & Pensions. Aspect Financial Service Limited t/a Aspect Life & Pensions, Aspect Mortgages is regulated by The Central Bank of Ireland. Registered Number C501905'
		//text = 'All rights reserved. Copyright © National Pension Helpline.'
	}
	if (companyId === 'al') {
		text = 'All rights reserved. Copyright © Pension Compare. Powered by Aspect Life & Pensions. Aspect Financial Service Limited t/a Aspect Life & Pensions, Aspect Mortgages is regulated by The Central Bank of Ireland. Registered Number C501905'
	}
	if (companyId === 'bq') {
		text = 'All rights reserved. Copyright © National Pension Helpline. Powered by BeQuick Media. This is a test deployment'
	}
	return text
}

export function getPrimaryGoalOptions(companyId) {
	let options = primaryGoalOptions
	if (companyId === 'al') {
		options = primaryGoalOptionsPc
	}
	return options
}


export function getPrivacyPolicy(companyId) {
	let link = null
	if (companyId === 'nph') {
	    link = 'https://nationalpensionhelpline.ie/privacy-policy/'
	}
	if (companyId === 'bq') {
		link = 'https://nationalpensionhelpline.ie/privacy-policy/'
	}
	if (companyId === 'al') {
		link = 'https://pensioncompare.ie/privacy-policy/'
	}
	return link
}